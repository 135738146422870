import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
// import { AuthGuard } from './shared/auth.guard';
import { BodyComponent } from './main/body/body.component';
import { ErrorComponent } from './main/error/error.component';
import { UnauthorizedComponent } from './main/unauthorized/unauthorized.component';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./main/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  // {
  //   path: '',
  //   component: BodyComponent,
  //   // canActivate: [AuthGuard],
  //   // canActivateChild: [AuthGuard],
  //   children: [
  //     { path: 'dashboard', loadChildren: () => import('./main/app/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //     { path: 'myprofile', loadChildren: () => import('./main/app/myprofile/myprofile.module').then(m => m.MyprofileModule) },
  //   ]
  // },
  {
    path: '',
    component: BodyComponent,
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [

      { path: 'thankyou', loadChildren: () => import('./main/app/thankyou/thankyou.module').then(m => m.ThankyouModule) },

      { path: 'createjob', loadChildren: () => import('./main/app/createjob/createjob.module').then(m => m.CreatejobModule) },

    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
