import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  isBrowser: any;

  public SDDL = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    enableCheckAll: false,
    itemsShowLimit: 3,
    allowSearchFilter: false,
    closeDropDownOnSelection: true
    // maxHeight: 150
  };
  constructor(private toastr: ToastrService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: string,) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  public ToastSuccess(msg: any, heading = 'Success') {
    this.toastr.success(msg, heading, {
      enableHtml: true,
      closeButton: true,
    });
  }
  public ToastWarning(msg: any, heading = 'Warning') {
    this.toastr.warning(msg, heading, {
      enableHtml: true,
      closeButton: true,
    });
  }
  public ToastError(msg: any, heading = 'Error') {
    this.toastr.error(msg, heading, {
      enableHtml: true,
      closeButton: true,
    });
  }
  public ToastClear() {
    this.toastr.clear();
  }
  public GotoURL(url: string) {
    this.router.navigate([url]);
  }
  public GotoURLParam(url: string) {
    this.router.navigateByUrl(url);
  }
  public placeholder(object: object, key: any) {
    return Object.assign(object, key);
  }
  public Encrypt(o: any, salt: any) {
    o = JSON.stringify(o).split('');
    for (let i = 0, l = o.length; i < l; i++) {
      if (o[i] === '{') {
        o[i] = '}';
      } else if (o[i] === '}') {
        o[i] = '{';
      }
    }
    return btoa(encodeURI(salt + o.join('')));
  }
  public Decrypt(o: any, salt: any) {
    o = decodeURI(atob(o));
    if (salt && o.indexOf(salt) !== 0) {
      throw new Error('object cannot be decrypted');
    }
    o = o.substring(salt.length).split('');
    for (let i = 0, l = o.length; i < l; i++) {
      if (o[i] === '{') {
        o[i] = '}';
      } else if (o[i] === '}') {
        o[i] = '{';
      }
    }
    return JSON.parse(o.join(''));
  }
  public LocalStorageSet(name: string, data: any) {
    if (this.isBrowser) {
      return localStorage.setItem(
        name,
        this.Encrypt(JSON.stringify(data), name)
      );
    }
  }
  public LocalStorageGet(name: string) {
    if (this.isBrowser) {
      return JSON.parse(this.Decrypt(localStorage.getItem(name), name));
    }
  }
  public SessionStorageSet(name: string, data: any) {
    if (this.isBrowser) {
      return sessionStorage.setItem(
        name,
        this.Encrypt(JSON.stringify(data), name)
      );
    }
  }
  public SessionStorageGet(name: string) {
    if (this.isBrowser) {
      const Data = sessionStorage.getItem(name);
      if (Data && Data !== null) {
        return JSON.parse(this.Decrypt(Data, name));
      }
    }
  }
  public SwalSuccess(msg: string, heading = 'Success!') {
    Swal.fire({
      title: heading,
      text: msg,
      icon: 'success',
      confirmButtonColor: '#7e3f97',
    });
  }
  public SwalWarning(msg: any, heading = 'Warning') {
    Swal.fire(msg, heading, 'warning');
  }
  public SwalError(msg: any, heading = 'Error') {
    Swal.fire(msg, heading, 'error');
  }
  public Swalhtml(Heading: any, msg: any) {
    Swal.fire({
      title: Heading,
      html: msg,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: false,
    }).then((result) => {
    });
  }

  // ===========Alphabets Only===============


// ==================================
  public AlphabetsOnly(event: any) {
    if (event.which === 32 && event.target.selectionStart === 0) {
      return false;
    } else {
      const pattern = /^[a-zA-Z ]*$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
  // ===========Number Only===============

  public NumberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // ==============================================
  public Alphabets_Numbers(event: any) {
    if (event.which === 32 && event.target.selectionStart === 0) {
      return false;
    } else {
      const pattern = /^[a-zA-Z0-9 ]*$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
// ================================================
  public Alpha_No_Without_Space(event: any) {
    if (event.which === 32 && event.target.selectionStart === 0) {
      return false;
    } else {
      const pattern = /^[a-zA-Z0-9]*$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }
// =================================================
public NoSpace(event: any) {
  if (event.keyCode === 32) {
    return false;
  }
}


}
